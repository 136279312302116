export function convertData(logger, message, data) {
  if (!data || !Object.keys(data).length) {
    return message
  }

  const loggingData = { message, data }

  if (logger === 'local' || typeof window !== 'undefined') {
    return loggingData
  }

  return JSON.stringify({ message, data })
}

function dolog(level, message, data) {
  const logger = process.env.APP_ENV || 'local'

  console[level](convertData(logger, message, data))
}

const log = dolog.bind(null, 'log')
const error = dolog.bind(null, 'error')
const warn = dolog.bind(null, 'warn')
const info = dolog.bind(null, 'info')

export default { log, info, warn, error }
