export const notFoundContent = {
  title: 'Page not found',
  subtitle:
    "Sorry, the page you are looking for doesn't exist or has been moved.",
  btnLabel: 'Back to homepage',
  image: {
    src: '/next-assets/not-found-page/not-found.webp',
    alt: 'not-found-img',
  },
}
