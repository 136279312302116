import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../src/components/layouts'
import ErrorPage from '../src/components/pages/Error'

const Error = ({ statusCode }) => (
  <Layout statusCode={statusCode} footerClassName="max-w-1400">
    <ErrorPage statusCode={statusCode} />
  </Layout>
)

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res?.statusCode || err?.statusCode || 404

  return { statusCode }
}

Error.defaultProps = {
  statusCode: 404,
}
Error.propTypes = {
  statusCode: PropTypes.number,
}

export default Error
