import Hyperlink from '../../shared/HyperLink'
import Img from '../../shared/Img'
import { notFoundContent } from './consts'
import styles from './index.module.scss'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import logger from '../../../common/logger'

const ErrorPage = ({ statusCode }) => {
  const { title, subtitle, image, btnLabel } = notFoundContent

  const error = useMemo(() => {
    switch (Number(statusCode)) {
      case 404:
        return {
          title: '404 ERROR',
          subtitle: "Sorry, this page isn't available",
          text: 'The link you followed may be broken, or the page may have been removed.',
        }
      default:
        return {
          title: `${statusCode} ERROR`,
          subtitle: 'Oops, something went wrong',
          text: 'The link you followed may be broken, or the page may have been removed.',
        }
    }
  }, [statusCode])

  return (
    <div
      className="w-content mx-auto max-w-1400 px-4 py-16 lg:p-0"
      data-testid="error-page"
    >
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-6">
        <div className="flex flex-col justify-center lg:col-span-4 lg:col-start-2">
          <h1
            className="mb-4 whitespace-nowrap text-4xl font-medium lg:mb-6 lg:text-4.9xl"
            data-testid="error-title"
          >
            {title}
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-265 lg:mb-12">
            {subtitle}
          </p>
          <Hyperlink
            href="/"
            childrenClassNames="bg-blue-560 leading-none py-4 px-6 text-lg font-bold text-white lg:w-fit rounded-lg text-center"
            aria-label="back-to-home"
            clickTracking={false}
          >
            {btnLabel}
          </Hyperlink>
        </div>
        <Img
          src={image.src}
          alt={image.alt}
          layout="responsive"
          objectFit="cover"
          className={`mt-16 h-60 w-full md:h-96 lg:col-span-6 lg:col-start-7 lg:mt-0 lg:h-600 ${styles['flippedImage']}`}
          imgClassName="object-top lg:object-center"
          priority={true}
        />
      </div>
    </div>
  )
}

ErrorPage.propTypes = {
  statusCode: PropTypes.number.isRequired,
}

export default ErrorPage
